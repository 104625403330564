import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import './relatedPosts.css';

const RelatedPosts = ({ currentPostId, categories }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityPost(limit: 4, sort: { fields: publishedAt, order: DESC }) {
        edges {
          node {
            id
            title
            slug {
              current
            }
            categories {
              title
            }
          }
        }
      }
    }
  `);

  const relatedPosts = data.allSanityPost.edges.filter(
    ({ node }) =>
      node.id !== currentPostId &&
      node.categories.some((category) =>
        categories.map((cat) => cat.title).includes(category.title)
      )
  );

  return (
    <aside className="related-posts" aria-labelledby="related-posts-heading">
      <h3 id="related-posts-heading">Artigos Relacionados</h3>
      <ul>
        {relatedPosts.map(({ node }) => (
          <li key={node.id}>
            <Link to={`/blog/${node.slug.current}`} aria-label={`Leia mais sobre ${node.title}`}>
              {node.title}
            </Link>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default RelatedPosts;
