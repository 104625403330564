import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import './authorInfo.css';

const AuthorInfo = ({ author }) => {
  return (
    <div className="author-info">
      <h3 id="author-info-title">Sobre o Autor</h3>
      <p>{author.name}</p>
      <div className="author-social-links" aria-labelledby="author-info-title">
        <a
          href={`https://www.facebook.com/${author.facebook}`}
          target="_blank"
          rel="noopener noreferrer"
          className="author-icon"
          aria-label={`Perfil no Facebook de ${author.name}`}
        >
          <FaFacebookF aria-hidden="true" />
        </a>
        <a
          href={`https://twitter.com/${author.twitter}`}
          target="_blank"
          rel="noopener noreferrer"
          className="author-icon"
          aria-label={`Perfil no Twitter de ${author.name}`}
        >
          <FaTwitter aria-hidden="true" />
        </a>
        <a
          href={`https://www.linkedin.com/in/${author.linkedin}`}
          target="_blank"
          rel="noopener noreferrer"
          className="author-icon"
          aria-label={`Perfil no LinkedIn de ${author.name}`}
        >
          <FaLinkedinIn aria-hidden="true" />
        </a>
        <a
          href={`https://www.instagram.com/${author.instagram}`}
          target="_blank"
          rel="noopener noreferrer"
          className="author-icon"
          aria-label={`Perfil no Instagram de ${author.name}`}
        >
          <FaInstagram aria-hidden="true" />
        </a>
      </div>
      {/* Adicione mais detalhes sobre o autor aqui */}
    </div>
  );
};

export default AuthorInfo;
