import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/Seo';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { PortableText } from '@portabletext/react';
import './blog-post.css';
import DemoRequestSection from '../components/DemoRequestSection/DemoRequestSection';
import LatestNewsSection from '../components/LatestNewsSection/LatestNewsSection';
import CallToActionSection from '../components/CallToActionSection/CallToActionSection';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';
import { Disqus, CommentCount } from "gatsby-plugin-disqus";
import RelatedPosts from '../components/RelatedPosts/relatedPosts';
import ShareButtons from '../components/ShareButtons/shareButtons';
import AuthorInfo from '../components/AuthorInfo/authorInfo';

const BlogPostTemplate = ({ data }) => {
  const { sanityPost: post } = data;
  const image = post.mainImage ? getImage(post.mainImage.asset) : null;

  const extractTextFromBlocks = (blocks) => {
    return blocks
      .filter(block => block._type === 'block')
      .map(block => block.children.map(child => child.text).join(' '))
      .join(' ');
  };

  const calculateReadingTime = (text) => {
    const wordsPerMinute = 200;
    const textLength = text.split(' ').length;
    return Math.ceil(textLength / wordsPerMinute);
  };

  const bodyText = extractTextFromBlocks(post._rawBody);
  const readingTime = calculateReadingTime(bodyText);

  const crumbs = [
    { path: '/', label: 'Página Inicial' },
    { path: '/blog', label: 'Blog' },
    { path: `/blog/${post.slug.current}`, label: post.title }
  ];

  const disqusConfig = {
    url: `${typeof window !== 'undefined' ? window.location.origin + window.location.pathname : ''}`,
    identifier: post.id,
    title: post.title,
  };

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />
      <Breadcrumbs crumbs={crumbs} />
      <article className="blog-post">
        <header className="post-header">
          <h1 className="post-title-post-single">{post.title}</h1>
          <p className="published-date">{post.publishedAt}</p>
          <p className="reading-time">Tempo de leitura: {readingTime} minutos</p>
          {image && (
            <GatsbyImage
              image={image}
              alt={post.title}
              className="main-image"
            />
          )}
        </header>
        <section className="post-content">
          <PortableText value={post._rawBody} />
        </section>
        <ShareButtons url={typeof window !== 'undefined' ? window.location.href : ''} title={post.title} />
        <AuthorInfo author={post.author} />
        <RelatedPosts currentPostId={post.id} categories={post.categories} />
        <div>
        <Disqus config={disqusConfig} />
        <CommentCount config={disqusConfig} placeholder={""} />
      </div>
      </article>
      <DemoRequestSection />
      <LatestNewsSection />
      <CallToActionSection />
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    sanityPost: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      publishedAt: PropTypes.string.isRequired,
      excerpt: PropTypes.string,
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }).isRequired,
      _rawBody: PropTypes.array.isRequired,
      mainImage: PropTypes.shape({
        asset: PropTypes.object,
      }),
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
        })
      ),
      author: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  query($slug: String!) {
    sanityPost(slug: { current: { eq: $slug } }) {
      id
      title
      publishedAt(formatString: "MMMM DD, YYYY")
      excerpt
      slug {
        current
      }
      _rawBody
      mainImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 1200)
        }
      }
      categories {
        title
      }
      author {
        name
      }
    }
  }
`;

export default BlogPostTemplate;
