import React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import './shareButtons.css';

const ShareButtons = ({ url, title }) => {
  return (
    <div className="share-buttons" aria-label="Botões de Compartilhamento">
      <h3>Compartilhe este artigo</h3>
      <div className="share-buttons-list" role="list">
        <FacebookShareButton 
          url={url} 
          quote={title} 
          className="share-button" 
          aria-label="Compartilhar no Facebook"
          role="listitem"
        >
          <FaFacebookF aria-hidden="true" />
        </FacebookShareButton>
        <TwitterShareButton 
          url={url} 
          title={title} 
          className="share-button" 
          aria-label="Compartilhar no Twitter"
          role="listitem"
        >
          <FaTwitter aria-hidden="true" />
        </TwitterShareButton>
        <LinkedinShareButton 
          url={url} 
          title={title} 
          className="share-button" 
          aria-label="Compartilhar no LinkedIn"
          role="listitem"
        >
          <FaLinkedinIn aria-hidden="true" />
        </LinkedinShareButton>
        <WhatsappShareButton 
          url={url} 
          title={title} 
          className="share-button" 
          aria-label="Compartilhar no WhatsApp"
          role="listitem"
        >
          <FaWhatsapp aria-hidden="true" />
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default ShareButtons;
